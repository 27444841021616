<template>
	<div class="narrow_container tenants_container">
		<p v-if="!referencesLoaded" class="text_center">Loading Form....</p>
		<form-headings v-if="referencesLoaded" :heading="heading" :subhead="subhead" :instruction="instruction"></form-headings>
		<form method="POST" @submit.prevent="onSubmit">
			<reference-picker
				:form="form"
				:heading="heading" :subhead="subhead" :instruction="instruction"
				v-on:references-loaded="referencesLoaded = true"
				v-on:reference-confirming="confirmingReferences"
				v-on:reference-passed="referencesPassed"
			>	
			</reference-picker>	
			<div class="clear"></div>
            <p v-if="hasError('VendorTxCode')" v-text="getError('VendorTxCode')"></p>
			<div v-if="referenceCorrect">
				<input-text :non-editable="true" label="Property Address" name="Description" :form="form" :errors="errors"></input-text>
				<input-text placeholder="123.45" label="Amount (££.pp)" name="Amount" :form="form" :errors="errors"></input-text>
            	<input-text label="First Name(s)" name="BillingFirstnames" :form="form" :errors="errors"></input-text>
            	<input-text label="Surname" name="BillingSurname" :form="form" :errors="errors"></input-text>
            	<input-text label="Email Address" name="CustomerEMail" :form="form" :errors="errors"></input-text>
            	<div class="form-group row">
	                <div class="col-sm-6">
	                    <label>Confirm Email Address</label>
	                </div>
	                <div class="col-sm-6">
	                    <input class="form-control payment_form_field" type="text" v-model="confirmEmail">
	                    <p class="validation_error" v-if="form.CustomerEMail != '' && !emailConfirmed">Please ensure the email addresses are matching</p>
	                </div>
	                <div class="clear"></div>
	            </div>
	            <input-text label="Card Billing Address" name="BillingAddress1" :form="form" :errors="errors"></input-text>
	            <input-text label="Card Billing Address (optional)" name="BillingAddress2" :form="form" :errors="errors"></input-text>
	            <input-text label="Town" name="BillingCity" :form="form" :errors="errors"></input-text>
	            <input-text label="Postcode" name="BillingPostCode" :form="form" :errors="errors"></input-text>
	            <input-text label="Telephone (optional)" name="BillingPhone" :form="form" :errors="errors"></input-text>

	            <div class="row">
	                <div class="col-sm-6 float_right">
	                    <button class="btn btn-default proceed_button" v-if="formSubmittable">Proceed</button>
	                </div>
	                <div class="clear"></div>
	            </div>
			</div>
		</form>
	</div>
</template>
<script>
	import FormHeadings from './FormHeadings.vue';
	import ReferencePicker from './ReferencePicker.vue';
	import InputText from './../form/inputText.vue';
	export default{
		components:{
			FormHeadings,ReferencePicker,InputText
		},
		computed:{
			emailConfirmed(){
                if(this.form.CustomerEMail != '' && this.form.CustomerEMail == this.confirmEmail) return true;
                return false;
            },
            formSubmittable(){
                if(
                this.form.BillingFirstnames != '' &&
                this.form.BillingSurname != '' &&
                this.form.CustomerEMail != '' &&
                this.form.BillingAddress1 != '' &&
                this.form.BillingCity != '' &&
                this.form.BillingPostCode != '' &&
                this.form.Description != '' &&
                this.form.VendorTxCode != '' &&
                this.emailConfirmed == true &&
                this.form.Amount != '') return true;
                return false;
            }
		},
		methods:{
			onSubmit(){
                let $this = this;
                swal({
                  title: "Proceed to payment?",
                  text: "Please confirm you have provided the correct details and then press 'OK'",
                  type: "info",
                  showCancelButton: true,
                  closeOnConfirm: false,
                  showLoaderOnConfirm: true,
                },
                function(){
                    axios.post('/payment_form', $this.form)
                     .then((response) => {
                        swal("Thank you for your details. Redirecting you to Sagepay for payment...");
                        setTimeout(function(){
                            window.location = response.data;
                        }, 1000);
                     })
                     .catch((errors) => {
                     	swal("Form Errors!", "Sorry there were some errors with the information you provided. Please correct those and try again.", "error");
                        $this.errors = errors.response.data;
                     });
                });
            },
			hasError(key){
                return (typeof this.errors[key] == 'object');
            },
            getError(key){
                return this.errors[key][0];
            },
			referencesPassed(){
                this.referenceCorrect = true;
                this.heading = '';
                this.subhead = '';
                this.instruction = 'We accept payments by debit or credit card, surcharges may apply. <br/>When you click proceed you will be transferred to our secure payment portal.'    
        		this.$emit('reference-passed');
			},
			confirmingReferences(filter){
				this.$emit('reference-confirm');
            	this.heading = filter[0].description;
            	this.subhead = 'You have entered a reference number for';
            	this.instruction = 'If correct please proceed. If incorrect please close this window and check that your reference number is correct';
			}
		},
		mounted(){
		},
		name:"CustomerForm",
		data(){
			return{
				referencesLoaded:false,
				referenceCorrect:false,
				heading:'Welcome to the Y&Y Management Payment Portal',
                subhead:'',
                instruction:'Please enter your reference number below to proceed',
                errors:[],
                confirmEmail:'',
                form:{
                    BillingFirstnames:'',
                    BillingSurname:'',
                    CustomerEMail:'',
                    BillingAddress1:'',
                    BillingAddress2:'',
                    BillingCity:'',
                    BillingPostCode:'',
                    BillingPhone:'',
                    Description:'',
                    VendorTxCode:'',
                    Amount:''
                }
			}
		}
	}
</script>