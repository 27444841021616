
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue/dist/vue.js');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
*/
Vue.component('payment-form', require('./vue_components/PaymentForm.vue'));
Vue.component('team-page', require('./vue_components/TeamPage.vue'));
Vue.component('services-page', require('./vue_components/ServicesPage.vue'));
Vue.component('testimonials', require('./vue_components/Testimonials.vue'));

if($('#app').length > 0){
	const app = new Vue({
	    el: '#app'
	});
}

