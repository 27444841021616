<template>
	<div>
        <template v-if="refType == undefined">
            <div>Please confirm your reference type:</div>
            <div @click="refType = '3part'" class="btn " style="margin-top:20px;">
                3 Part Reference */*/*
            </div>
            <div @click="refType = '4part'" class="btn " style="margin-top:20px;">
                4 Part Reference */*/*/*
            </div>
        </template>
        <template v-if="refType != undefined">
            <div class="form-group row" v-show="!confirmingReference">
                <div :class="{'col-sm-5': !referenceCorrect, 'col-sm-6': referenceCorrect} ">
                    <label>Reference</label>
                </div>
                <div :class="{'col-sm-7': !referenceCorrect, 'col-sm-6': referenceCorrect} ">
                    <div v-if="!referenceCorrect">
                        <div class="row">
                            <div class="ref_box" :class="{'col-xs-4 col-sm-2': refType === '3part', 'col-xs-3 col-sm-2': refType === '4part'}">    
                                <input @keydown.prevent.enter="performLookUp" class="form-control payment_form_field " type="text" v-model="VendorTxCode1"> <div class="forwardslash">/</div>
                            </div>
                            <div class="ref_box" :class="{'col-xs-4 col-sm-2': refType === '3part', 'col-xs-3 col-sm-2': refType === '4part'}">    
                                <input @keydown.prevent.enter="performLookUp" class="form-control payment_form_field " type="text" v-model="VendorTxCode2"> <div class="forwardslash">/</div>
                            </div>
                            <div class="ref_box" :class="{'col-xs-4 col-sm-2': refType === '3part', 'col-xs-3 col-sm-2': refType === '4part'}">    
                                <input @keydown.prevent.enter="performLookUp" class="form-control payment_form_field" type="text" v-model="VendorTxCode3"> <div v-if="refType === '4part'" class="forwardslash">/</div>
                            </div>
                            <div class="col-xs-3 col-sm-2 ref_box" v-if="refType === '4part'">    
                                <input @keydown.prevent.enter="performLookUp" class="form-control payment_form_field" type="text" v-model="VendorTxCode4"> 
                            </div>
                            <div class="col-xs-4 search_button">    
                                <div @click="performLookUp" class="btn ">
                                    Proceed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <input readonly class="form-control readonly_form col-sm-12" type="text" v-model="reference">
                    </div>
                </div>
            </div>
        </template>
        
        <div @click="reloadForm" class="btn ten_btn" v-show="confirmingReference">BACK</div>
        <div @click="referenceConfirmed" class="btn ten_btn" v-show="confirmingReference">PROCEED</div>
	</div>
</template>
<script>
	export default{
		name:"ReferencePicker",
		mounted() {
            axios.get('/references')
                .then((response) => {
                    this.references = response.data;
                    this.$emit('references-loaded');
                });
        },
        props:{
        	form:{required:true}
        },
        methods:{
        	reloadForm(){
        		window.location = '/pages/view/tenants';
        	},
			referenceConfirmed(){
				this.confirmingReference = false;
				this.referenceCorrect = true;
				this.form.VendorTxCode = this.reference.replace(/\//g, '');
				this.$emit('reference-passed');
			},
        	performLookUp(){
                let $this = this;
                let filter = this.references.filter((ref) => {
                    if (typeof ref.reference != 'string') return false;
                    return ref.reference.toLowerCase() == this.reference.toLowerCase();
                });
                if(filter.length == 1){
                	this.form.Description = filter[0].description;
                	$this.$emit('reference-confirming', filter);
                	this.confirmingReference = true;
                }else{
                    swal("Error", "Sorry, we couldn't find a matching reference. Please try another.", "error");
                }
            },
        },
        computed:{
            ucReference(){
                if (this.refType === '4part') {
                    return this.VendorTxCode4.toUpperCase();
                }
                return this.VendorTxCode3.toUpperCase();
            },
        	reference(){
                if (this.refType === '4part') {
                    return this.VendorTxCode1 + '/' + this.VendorTxCode2 + '/' + this.VendorTxCode3 + '/' + this.ucReference;
                }
                return this.VendorTxCode1 + '/' + this.VendorTxCode2 + '/' + this.ucReference;
            }
        },
        data(){
        	return {
        		references:[],
        		confirmingReference:false,
        		referenceCorrect:false,
        		VendorTxCode1:'',
				VendorTxCode2:'',
                VendorTxCode3:'',
                VendorTxCode4:'',
                refType: undefined
        	}
        }
	}
</script>