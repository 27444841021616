<template>
<div>
    <div :class="{blue_bg:usingBlueBg,darkgrey_bg:usingGreyBg}">
        <div :class="{blue_bg_container:usingBlueBg,dark_grey_bg_container:usingGreyBg}" class="container-fluid ">
            <slot name="header"></slot>
            <customer-form v-on:reference-passed="changeToBlue" v-on:reference-confirm="changeToGrey"></customer-form>
        </div>
    </div>
    <div class="container-fluid">
        <slot name="footer"></slot>
    </div>
 </div>
</template>

<script>
    import CustomerForm from './Payments/CustomerForm.vue';
    export default {
        components:{CustomerForm},
        name:"PaymentForm",
        mounted() {},
        computed:{
            usingGreyBg(){
                return !this.usingBlueBg;
            }
        },
        watch:{},
        methods:{
            changeToBlue(){
                this.usingBlueBg = true;
            },
            changeToGrey(){
                this.usingBlueBg = false;
            }
        },
        data(){
            return {
                usingBlueBg:true,
            }
        }
    }
</script>
<style lang="sass">
    
</style>