<template>
	<div class="form-group row">
        <div class="col-sm-6">
            <label>{{label}}</label>
        </div>
        <div class="col-sm-6">
            <div v-if="nonEditable">
                <input 
                    class="form-control readonly_form" 
                    type="text" 
                    :name="name"
                    :readonly="nonEditable"
                    v-model="form[name]" 
                />
            </div>
            <div v-else>
                <input 
                    class="form-control payment_form_field" 
                    type="text" 
                    :name="name"
                    :placeholder="placeholder" 
                    v-model="form[name]" 
                />
            </div>
            <p class="validation_error" v-if="hasError(name)" v-text="getError(name)"></p>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
	export default{
		name:"InputText",
        methods:{
            hasError(key){
                return (typeof this.formErrors[key] == 'object');
            },
            getError(key){
                return this.formErrors[key][0];
            }
        },
        computed:{
            formErrors(){
                return this.errors;
            }
        },
		props:{
			label:{required:true},
            name:{required:true},
            form:{required:true},
			errors:{required:true},
            placeholder:{default:''},
            nonEditable:{default:false}
		}
	}
</script>