<template>
    <div class="relative">
        <slot></slot>
        
        <ul class="txt_center testimonial_slider">
            <li class="absolutely_centered" v-for="(testimonial, i) in testimonials" :class="{visible_testimonial:testimonial.active}">
                <h4 class="">“{{testimonial.content}}”</h4>
                <img class="test_icon icons" src="/images/test_icon.svg">
                <p v-if="testimonial.signoff != ''">{{testimonial.signoff}}</p>
                <p v-if="testimonial.reference != ''" class="xlight_font">{{testimonial.reference}}</p>
            </li>
        </ul>   
    </div>  
</template>

<script>
    export default {
        name:"Testimonials",
        mounted() {
            axios.get('/posts/testimonials')
            .then((response) => {
                response.data.forEach((item) => {
                    item.active = false;
                });
                this.testimonials = response.data;
                this.testimonials[0].active = true;
                this.animateSlider(0);
            });
            
        },
        methods:{
            animateSlider(key){
                let Next = (key == (this.testimonials.length - 1)) ? 0 : (key + 1);
                setTimeout(() => {
                    this.testimonials[key].active = false;
                    this.testimonials[Next].active = true;
                    this.animateSlider(Next);
                }, 6000);
            }
        },
        data(){
            return {
                testimonials:[],
            }
        }
    }
</script>
<style lang="sass">
</style>