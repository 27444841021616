<template>
    <div>
        <div class="relative" v-if="showServicesNav">  
            <div class="service_nav txt_center">
                <h4 @click="toggleNav"><img class="burger_bar" src="/images/burger_bar.png"> Services Menu</h4>
                <ul>
                    <li v-for="service in services" :class="{active_service:viewData.active_service == service.slug}"><a :href="'/services/view/' + service.slug">{{service.title}}</a></li>
                    <li><a href="/tenants">Payment Portal</a></li>
                </ul>
                <img @click="toggleNav" class="black_x" src="/images/black_x.png">
            </div>
        </div>
        <div class="service_single_content relative">
            <div class="service_blue_overlay">
                
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name:"ServicesPage",
        mounted() {
            axios.get('/posts/services')
            .then((response) => {
                this.services = response.data;
            });
            // $(window).scroll(() => {
            //     var navOffset = $(".service_single_content").offset().top - $(window).scrollTop();
            //     let NavHeight = $('.service_nav').outerHeight();
            //     if((navOffset - NavHeight) <= 0){
            //         $(".service_nav").addClass('sticky');
            //         if(!$('.service_single_content').hasClass(':sticky')){
            //             $('.service_single_content').addClass('sticky').css({
            //                 marginTop:NavHeight
            //             });
            //         }
            //     }else{
            //         $(".service_nav, .service_single_content").removeClass('sticky');
            //         $('.service_single_content').removeClass('sticky').css({
            //             marginTop:0
            //         });
            //     }
            // });
        },
        methods:{
            toggleNav(){
                $('.service_nav > ul').slideToggle();
                $('.service_blue_overlay, .service_nav > .black_x').toggleClass('showing');
            }
        },
        data(){
            return {
                services:[],
                showServicesNav:false,
                viewData
            }
        }
    }
</script>
<style lang="sass">
</style>