<template>
	<div>
		<h4 v-if="subhead != ''" class="title light_font">
			{{subhead}}
		</h4>
		<h3 v-if="heading != ''" class="bold title">
			{{heading}}
		</h3>
    	<h4 v-if="instruction != ''" class="light_font" v-html="instruction"></h4>
	</div>
</template>
<script>
	export default{
		name:"FormHeadings",
		props:{
			subhead:{required:true},
			heading:{required:true},
			instruction:{required:true}
		}
	}
</script>