<template>
    <div class="">
        <div @click="hideOverlay" class="team_blue_overlay"></div>
        <div v-if="activeTeam.title" class="single_team_box grey_text">
            <div class="col-sm-6">
                <img class="full_width_image single_team_image" :src="'/' + activeTeam.associated_files">
            </div>
            <div class="col-sm-6">
                <div class="single_team_content">
                    <div class="close_team" @click="hideOverlay">X</div>
                    <h4 class="bold">{{activeTeam.title}} <span v-if="activeTeam.qualifications">{{activeTeam.qualifications}}</span></h4>
                    <h4 v-if="activeTeam.job_description != ''">{{activeTeam.job_description}}</h4>
                    <p clas="blue">{{activeTeam.date_joined}}</p>

                    <div id="team_bio" v-html="activeTeam.content"></div>
                    <p v-if="activeTeam.email != ''" class="no_bottom_margin">E: <a :href="'mailto:' + activeTeam.email">{{activeTeam.email}}</a></p>
                    <p v-if="activeTeam.telephone != ''" class="no_bottom_margin">T: {{activeTeam.telephone}}</p>
                </div>
            </div>    
        </div>
        <div class="grey_bg relative top_bottom_padding site_side_padding">
            
            <div class="team_menu">
                <h4 class=" text_center">Meet Our Team</h4>
                <ul>
                    <li :class="{active_filter:filteredRole == 1}" @click="filterRoles(1)">
                        Property Managers
                        <div class="dot"></div>
                    </li>
                    <li :class="{active_filter:filteredRole == 0}" @click="filterRoles(0)">
                        Site Managers
                        <div class="dot"></div>
                    </li>
                    
                    <li :class="{active_filter:filteredRole == 2}" @click="filterRoles(2)">
                        Accounts Team
                        <div class="dot"></div>
                    </li>
                </ul>
                
            </div>
            <div class="row " id="">
                <div id="team_boxes">
                    <div v-show="filteredRole == staff.role || filteredRole == null" @click="loadTeamBox(staff)" v-for="staff in employees" class="mix col-sm-3 grey_text relative team_box">
                        <img class="full_width_image team_circular" src="/images/team_overlay.png">
                        <img class="full_width_image" :src="'/' + staff.associated_files">
                        <img src="/images/blue_plus.png" class="blue_plus">
                        <div class="white_circle"></div>
                        <div class="team_content">
                            <p class="bold">{{staff.title}}</p>
                            <p>{{staff.job_description}} &nbsp;</p>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
    let Ps = require('perfect-scrollbar');
    export default {
        name:"TeamPage",
        mounted() {
            axios.get('/posts/team')
             .then((response) => {
                this.employees = response.data;
                this.filterRoles(1);
             });
        },
        computed:{},
        watch:{
        },
        methods:{
            filterRoles(role){
                this.filteredRole = role;
            },
            hideOverlay(){
                $('.team_blue_overlay').removeClass('showing_overlay');
                this.activeTeam = {};
            },
            loadTeamBox(staff){
                $('.team_blue_overlay').addClass('showing_overlay');
                this.activeTeam = staff;
                setTimeout(() => {
                    var container = document.getElementById('team_bio');
                    Ps.initialize(container);
                }, 1000);
            }
        },
        data(){
            return {
                employees:[],
                activeTeam:{},
                filteredRole:null
            }
        }
    }
</script>
<style lang="sass">
    .ps-container>.ps-scrollbar-y-rail {
        opacity:1;
        background:#ebedef;
    }
</style>